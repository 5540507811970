@import url("../variables.css");

nav {
	scroll-snap-align: start;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	border-bottom: 1px solid var(--text-color);
	margin: 5px;
}

.navbar-toggle {
	background-color: var(--primary-bg-color);
	border: none;
	color: var(--text-color);
}

.sitenav ul{
	width: 10rem;
}

nav ul {
	width: 35rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	list-style-type: none;
	font-size: 1.5rem;
}

nav ul img{
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

nav ul li a{
	padding: 10px;
	text-decoration: none;
	color: var(--text-color);
	transition: background-color 0.3s;
}

nav ul li a:hover {
    background-color: #575757;
}

@media (max-width: 768px) {
	.usernav ul{
		flex-direction: column;
		width: 30%;
	}

	.usernav ul li {
		margin: 10px;
	}

	.open {
		display: flex;
	}

	.closed {
		display: none;
	}
}
