.App {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
	scroll-behavior: smooth;
	height: 100vh;
}

.Homepage-content {
	height: 100vh;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.Homepage-content main {
	scroll-snap-align: start;
	position: relative;
}

.Homepage-content main h1 {
	color: white;
	position: absolute;
	top: 5%;
	left: 5%;
	font-size: 5em;
	opacity: 0;
	transition: opacity 2s ease;
}

.Homepage-content main p {
	color: white;
	position: absolute;
	top: 25%;
	left: 5%;
	font-size: 2vw;
	margin-right: 100px;
	opacity: 0;
}

.Homepage-content main button {
	position: absolute;
	top: 80%;
	left: 5%;
	width: 10rem;
	height: 5rem;
	background-color: white;
	color: black;
	border: none;
	border-radius: 100px;
	font-size: 1.3rem;
	transition: transform 0.2s ease-in-out;
}

.Homepage-content main button:hover {
	transform: scale(1.1);
}

.Homepage-content img {
	width: 100%;
	height: 100vh; /* Match the container height */
	transition: filter 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.in-view {
	transform: scale(1.05);
	filter: blur(8px);
}

@media (max-width: 768px) {
	.Homepage-content main p {
		font-size: 4vw;
		margin-right: 50px;
	}

	.Homepage-content main button {
		width: 6rem;
		height: 3rem;
		font-size: 0.7rem;
	}
}
