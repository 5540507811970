.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-container input {
  margin-top: 1rem;
}

.upload-container input::file-selector-button {
    font-weight: bold;
    color: dodgerblue;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
}

.upload-container button {
  margin: 3rem;
  font-size: 1.2rem;

  width: 7rem;
  height: 3rem;
}

#error-label {
  color: darkred;
  font-size: 1.5rem;
  display: none;
}

#checking-label {
  margin: 3rem;
  font-size: 1.5rem;
  display: none;
}
