@import url("variables.css");

body {
	margin: 0;
	padding: 0;

	background-color: var(--primary-bg-color);
	color: var(--text-color);
	font-family: Montserrat;
}
