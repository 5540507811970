.Loginpage {
  height: 100vh;

  display: flex;
  justify-content: center;
  overflow: hidden;
}

.inputform-container {
  margin-top: 10vh;
  width: 40vh;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-evenly;

  box-shadow: 0px 0px 5px 8px rgba(0,0,0,0.2);
  border-radius: 4rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.input-container input {
  margin: 10px;
  border-radius: 2rem;
  border: none;
  height: 2rem;
  background-color: var(--text-color);
}

#errorLabel {
  display: none;
  text-align: center;
  color: darkred;
  font-size: 1.5rem;
  width: 30vh;
}

#waiting{
  display: none;
  font-size: 1.5rem;
}

@keyframes text{
  0% {left: -50vw;}
  100% {left: 0%;}
}

.input-container #username-text-login{
  position: relative;
  animation-name: text;
  animation-duration: 0.5s;
}

.input-container #password-text-login{
  position: relative;
  animation-name: text;
  animation-duration: 1.1s;
}

@keyframes input{
  0% {left: 100vw;}
  100% {left: 0%;}
}

.input-container #username-input-login{
  position: relative;
  animation-name: input;
  animation-duration: 0.8s;
}

.input-container #password-input-login{
  position: relative;
  animation-name: input;
  animation-duration: 1.4s;
}

@keyframes button {
  0% {top: 100vh;}
  100% {top: 0%;}
}

.inputform-container #login-button{
  height: 3rem;
  width: 6rem;
  border: none;
  background-color: var(--text-color);
  border-radius: 2rem;
  color: black;

  position: relative;
  animation-name: button;
  animation-duration: 1.5s;
}
