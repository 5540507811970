.Profilepage {
  height: 50vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40vw;
  margin-top: 2rem;

  border: 1px solid var(--text-color);
  border-radius: 2rem;
}

.errorDiv {
  margin-top: 1rem;
  text-align: center;
  display: none;
  color: darkred;
}

.info-container {
  margin: 2rem;
  font-size: 1rem;
}

.info-label {
  color: gray;
  display: "block";
}

.error-label {
  color: darkred;
  display: none;
}

.change-input {
  display: none;
  height: 1.2rem;
  width: 15rem;
  font-size: 0.9rem;
}

.change-button {
  width: 5rem;
  height: 2rem;
  background-color: var(--text-color);

  border-radius: 0.5rem;
  border: none;

  transition: transform 0.1s;
  margin-left: 1rem;
  margin-right: 1rem;
}

.change-button:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .profile-container {
    width: 80vw;
  }
}
